












































import { get_technician, post_ticket_actions } from "@/api";
import { technicianType } from "@/api/api.types";
import { VuetifyForm } from "@/shared/types";
import { fullName } from "@/shared/utils";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "ticket-actions" })
export default class TicketActions extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;
  @Prop({ required: true }) readonly actions!: "reopen" | "close" | "reassign";

  /* Variables */

  formData = {
    uuid: "",
    note: "",
    assignedUser: "",
  };

  userSearch = "";

  userList = [] as technicianType["data"]["_embedded"]["technician"];

  userLoading = false;

  /* Computed */
  /* Methods */

  @Emit("submit")
  async submit() {
    try {
      this.formData["uuid"] = this.value;
      await post_ticket_actions(this.formData, this.actions);
      Notif.notif_success("Yeay!!!");
      this.close();
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  @Emit("input")
  close() {
    (this.$refs.formData as VuetifyForm).reset();
    return null;
  }

  async fetchUser() {
    this.userLoading = true;
    try {
      const resp = await get_technician(this.userSearch);
      if (resp.data._embedded.technician.length > 0) {
        this.userList = resp.data._embedded.technician;
        this.userList.map(
          (t) => (t.firstName = fullName(t.firstName, t.lastName))
        );
      }
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.userLoading = false;
    }
  }

  /* Life-cycle Methods */

  @Watch("userSearch")
  onUserSearchChange(now: string) {
    if (now) this.fetchUser();
  }
}
